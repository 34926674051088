import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import ArticleImage1 from "../assets/articleImages/1.png";
import ArticleImage2 from "../assets/articleImages/2.png";
import ArticleImage3 from "../assets/articleImages/3.png";
import ArticleImage4 from "../assets/articleImages/4.png";
import ArticleImage5 from "../assets/articleImages/5.png";
import ArticleImage6 from "../assets/articleImages/6.png";
import ArticleImage7 from "../assets/articleImages/7.png";
import ArticleImage8 from "../assets/articleImages/8.png";

const Articles = [
  {
    image: ArticleImage1,
    header: "和孩子沟通，得这么说才对！10幅漫画教你育儿金句！",
    text: "生活里，不少孩子的“熊样”总会让爸爸妈妈觉得闹心。经常迟到、打破东西、不做家务等等情况发生的次数不胜枚举。",
  },
  {
    image: ArticleImage2,
    header: "高情商爸妈睡前一定会问孩子的5个问题，坚持问，你一定能看到变化",
    text: "白天盯孩子盯得紧紧的，晚上到点就让娃睡觉。小E想知道，你是这样跟你家娃相处的吗？睡前是和孩子增进交流最好的时机，千万别这样浪费了。",
  },
  {
    image: ArticleImage3,
    header: "长期被父母批评的孩子，长大后都怎么样了？这6个育儿错误影响孩子一生",
    text: "今天小E要跟大家分享国外育儿网站BrightSide.me上非常火爆的一组漫画。这6张漫画戳中了许多中国父母在育儿路上不知不觉中就容易犯的毛病和问题。看过漫画才知道，爸妈在孩子小时候无意说的话、做的事，将对孩子成年后的人生产生意想不到巨大的影响。",
  },
  {
    image: ArticleImage4,
    header: "上海、香港、芬兰三地教育模式对比：我们离理想的教育还有多远？",
    text: "你也好奇上海、香港、芬兰这三个地方的小学生每天都过着怎样的生活吗？是各地怎样的教育模式，能让他们能够从全球竞争中脱颖而出？如果让他们互相做对方的作业，情况又会怎样呢？这背后又有哪些启示？",
  },
  {
    image: ArticleImage5,
    header: "高管性侵“养女”案引众怒：10条安全准则告诉孩子应该怎样保护自己",
    text: "各位爸爸妈妈最近应该都在关注令人揪心的性侵养女案件，小E也和大家一样。一系列新闻看得小E气到原地爆炸，又悲痛又愤怒。相信很多家长已经开始思索：到底该怎么做才能保护自己的孩子远离侵害？小E今天为大家找到了一套非常好用的“儿童安全法则”。这些准则简单好记，可以帮助孩子养成保护自己的意识，建议大家收藏好，讲给孩子听！",
  },
  {
    image: ArticleImage6,
    header: "长不大的孩子背后都有两个“直升机父母”！最后一张图看哭了……",
    text: "育儿专家李芷怡在《儿童敏感期成长指南》中说：“很多父母打着爱孩子的旗号替孩子做事，实际是为了满足孩子依赖自己的需要，或者怕孩子收拾残局。”的确，这种过度保护孩子的爱，不是为了孩子好，而是为了自己好。",
  },
  {
    image: ArticleImage7,
    header: "特斯拉CEO的超模妈妈：打工养出亿万富翁儿子，她的人生更硬核！",
    text: "来啦，小伙伴们！最近小E身边猛刮一阵“御姐风”，办公室女同事们天天一股“看我乘风破浪”的架势Image。就连前几天小E躺床上一打开X音，就被两个霸气女神的视频吸了睛：邓文迪专访72岁梅耶·马斯克！",
  },
  {
    image: ArticleImage8,
    header: "《三十而已》顾佳引热议：完美妈妈是个坑，你可千万别掉下去了",
    text: "当大家都在兴致勃勃地看这位“全能妈妈”顾佳如何升级打怪，计划扶持老公和儿子走向“人生巅峰”时，小E的一个同事忍不住问了句：“是不是一定要成为顾佳那样，才是好妈妈？”妈妈这一角色，到底要做到什么程度才够呢？我们真的能成为、必须成为、想成为顾佳那样的“完美妈妈”吗？",
  },
];

const HeaderImageWrapper = styled.div`
  display: flex;
  max-width: 960px;
  margin: 0 auto;
  padding-bottom: 20px;
`;
const HeaderImage = styled.img`
  margin-left: auto;
`;

const Container = styled.div`
  max-width: 940px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  justify-content: center;
  margin-top: 20px;
`;
const Article = styled.div`
  display: flex;
  margin-bottom: 24px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
`;
const Image = styled.img`
  position: relative;
  width: 240px;
  padding: 12px;
  background: #fafafa;
  object-fit: contain;
  flex-shrink: 0;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 1px solid #f7f7f7;
`;
const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
const Header = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;

  font-weight: 500;
  color: #333;
  font-size: 18px;
`;
const Content = styled.div`
  font-size: 14px;
  flex-grow: 1;
`;
const Footer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PublishDate = styled.div`
  font-size: 12px;
  color: #8a8a8a;
`;

const PayButton = styled.div`
  width: fit-content;
  font-weight: bold;
  color: white;
  font-size: 12px;
  border-radius: 12px;
  padding: 4px 16px;
  color: #ff68b5;
  border: 1px solid #ff68b5;

  cursor: pointer;
  &:hover {
    background-color: #ff329b;
    color: white;
  }
`;

export default (props) => {
  const onPay = () => {
    const pay = window.confirm("阅读此文章请缴费，1元/篇");
    if (pay) {
      window.location = "/#/payment";
    }
  };

  return (
    <div>
      <HeaderImageWrapper>
        <HeaderImage
          src="//storyblok-cdn.ef.com.cn/f/9/x/eb97ff6f6a/city-top-cubes.svg"
          data-src="//storyblok-cdn.ef.com.cn/f/9/x/eb97ff6f6a/city-top-cubes.svg"
          data-sizes="auto"
          sizes="707px"
        ></HeaderImage>
      </HeaderImageWrapper>
      <Container>
        {Articles.map((a) => (
          <Article key={a.text}>
            <Image src={a.image} />
            <Wrapper>
              <Header>{a.header}</Header>
              <Content>{a.text}</Content>

              <Footer>
                <PublishDate>
                  来源： 北京英孚教育科技有限公司 原创 时间：2022.04.06
                </PublishDate>

                <PayButton onClick={onPay}>查看详情</PayButton>
              </Footer>
            </Wrapper>
          </Article>
        ))}
      </Container>
    </div>
  );
};

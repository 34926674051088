import axios from "axios";
class UserService {
  constructor() {
    this.axios = axios.create({
      baseURL: "https://bs-api.kids.ef.cn/api/v2/english1",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
  }

  async sendRegisterRequest(mobile, password) {
    return this.axios
      .post("/register", {
        email: mobile,
        password: password,
      })
      .then(
        (res) => {
          return res.data;
        },
        (err) => {
          return { isSuccess: false, error: err };
        }
      );
  }

  sendLoginRequest(mobile, password) {
    return this.axios
      .post("/login", { email: mobile, password: password })
      .then(
        (res) => {
          return res.data;
        },
        (err) => {
          return { isSuccess: false, error: err };
        }
      );
  }

  getLoginUser() {
    const value = window.localStorage.getItem("current-user");
    return value ?  JSON.parse(value) : null;
  }

  saveLoginUser(userInfo) {
    window.localStorage.setItem("current-user", JSON.stringify(userInfo));
  }

  logoutUser() {
    window.localStorage.removeItem("current-user");
  }
}

export default new UserService();

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    margin-top: 10px;
    font-size: 12px;
    @media screen and (max-width: 767px) {
        margin:0px 24px 90px 24px;
    }
    
    @media screen and (min-width: 768px) {
        width: 960px;
        margin: 0 auto;
    }
`
const Header = styled.div`
    margin-top: 20px;
    font-size: 32px;
    font-weight: bold;

@media screen and (max-width: 767px) {
    margin-bottom: 35px;
}

@media screen and (min-width: 768px) {
    text-align: center;
    margin-bottom: 60px;
}

`
const MainBlock = styled.div`
text-align: Justify;
text-justify: inter-ideograph; 
@media screen and (min-width: 768px) {
    margin:0px auto 72px auto;
}
`
const Content = styled.p`
    margin-bottom: 10px;
    font-size: 14px;
`

export default (props) => (
    <Container>
        <Header>
            服务纠纷处理方式及流程
        </Header>
        <MainBlock>
            <Content>亲爱的用户：</Content>
            <Content>若您在浏览本网站和/或使用本网站提供的服务过程中遇到纠纷，可仔细阅读本《纠纷处理方式及流程》中的内容，希望可以为您提供帮助。（如您未满18周岁，您应和您的父母或监护人一起查看本《纠纷处理方式及流程》，以确保您和您的父母或监护人均能理解本《纠纷处理方式及流程》）</Content>
            <Content>一、用户之间纠纷处理方式</Content>
            <Content>如果您与本网站其他用户之间发生纠纷，您或其他用户中任何一方均有权选择以下途径解决：</Content>
            <Content>（一）与争议相对方自主协商；</Content>
            <Content>（二）请求消费者协会或者其他依法成立的调解组织调解；</Content>
            <Content>（三）向有关行政部门投诉；</Content>
            <Content>（四）向人民法院提起诉讼。</Content>
            <Content>北京英点商务咨询有限公司（下称 “本公司”）作为本网站的网站运营方，若需要本公司提供帮助时，请您提供与您账号注册信息一致的个人有效身份证件、必要的国家行政或司法机关文件和其他北京英点商务咨询有限公司要求提供的相关证据，经本公司审核确认后，将给予您必要的协助和支持。</Content>
            <Content>二、用户与本公司之间纠纷处理方式 1. 如您对本公司的服务有任何异议的，可以通过compliance.china@ef.com与我们联系。</Content>
            <Content>2. 如纠纷无法通过上述方式解决，任何一方可将争议提交上海国际经济贸易仲裁委员会，依据其届时有效的仲裁规则由一名独任仲裁员在上海进行仲裁。仲裁裁决应为终局裁决，对您和本公司均具有约束力。</Content>
        </MainBlock>
    </Container>
)
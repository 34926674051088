import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 10px;
  font-size: 12px;
  @media screen and (max-width: 767px) {
    margin: 0px 24px 90px 24px;
  }

  @media screen and (min-width: 768px) {
    width: 960px;
    margin: 0 auto;
  }
`;
const Header = styled.div`
  margin-top: 20px;
  font-size: 32px;
  font-weight: bold;

  @media screen and (max-width: 767px) {
    margin-bottom: 35px;
  }

  @media screen and (min-width: 768px) {
    text-align: center;
    margin-bottom: 60px;
  }
`;
const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-justify: inter-ideograph;
  @media screen and (min-width: 768px) {
    margin: 0px auto 72px auto;
  }
`;
const Content = styled.p`
  white-space: pre-line;
  font-size: 14px;
`;

export default props => (
  <Container>
    <Header>网站用户服务协议</Header>
    <MainBlock>
      <Content>
        一、总 则
        <br />
        <br />
        1.1
        请您在浏览本网站和/或使用本网站提供的服务之前仔细阅读本协议所有条款，特别是免除或限制我们责任的条款（如您未满18周岁，您应和您的父母或监护人一起查看本协议的条款，以确保您和您的父母或监护人均理解并明确同意本协议条款）。如您不同意本协议条款，您可以选择不浏览本网站及使用本网站提供的服务。您浏览本网站及/或使用本网站提供的服务即表示您已充分理解并同意接受本协议的全部内容，并同意受本协议约束。
        <br />
        <br />
        1.2
        本协议可能会不定期更新，更行后的协议条款将在本页面公布并在公布时即生效。您同意将定期访问本页面，查看和了解更新后的协议条款。您在本协议更新后继续浏览本网站及/或使用本网站提供的服务即表示您已接受更新后的协议。如您不同意接受更新后的协议，请立即停止使用本网站及本网站提供的服务。
        <br />
        <br />
        二、服务内容
        <br />
        <br />
        2.1
        本网站提供的具体服务内容可能会不定期进行更新和/或增删。您同意，因业务发展需要，本网站有权单方面对本网站中的内容及/或本网站提供的服务的全部或部分在任何时候进行更新、增删、变更、暂停、限制、终止或撤销。
        <br />
        <br />
        2.2
        本网站提供的部分服务可能是收费服务。在此情况下，我们会在相关页面上做明确的提示。如您不同意支付该等费用或不同意相关的服务条款，您可不使用该等收费服务。
        <br />
        <br />
        2.3
        如您在本网站申请注册账户，您应对该账户的账号、密码和其他注册信息进行保密。如您的账号、密码或其他注册信息被窃，或您的账户遭到非法或未获授权的使用或有其他使用异常的情况，请立即通过本网站公布的处理方式通知我们并尽快修改您的密码。我们在收到您的通知后，可能会要求您进行身份验证，身份验证通过后，我们将采取相应的处理措施，包括但不限于暂停该账号户的登录和/或使用，暂停该账户部分或全部功能等。如您未能通过身份验证，我们有权拒绝您的要求。
        <br />
        <br />
        2.4
        当您的注册账户出现权属权纠纷时，为保障您的利益，我们有权采取相应措施，包括但不限于暂停该账户的登录和/或使用、停止该账户部分或全部功能等。
        <br />
        <br />
        三、使用规则
        <br />
        <br />
        3.1 您在使用网络服务时须遵守以下规定： (1)
        不得为任何非法目的而使用网络服务系统； (2)
        遵守所有与网络服务有关的网络协议、规定和程序；及 (3)
        不得利用本网站提供之服务进行任何可能对互联网的正常运转造成不利影响的行为。
        <br />
        <br />
        3.2
        您在本网站完成申请注册手续后，您将成为本网站的注册用户，您应严格按本协议及本网站公布的其他适用于注册用户的协议和条款的规定使用该注册账户，在任何情况下该注册账户仅供您本人使用，禁止赠与、出借、出租、转让或售卖。
        <br />
        <br />
        3.3
        作为注册用户，您应始终保证您的账户注册信息实时更新且准确无误。您有权编辑、更新、删除您的注册信息，但请注意，删除您的注册信息可能会导致我们无法向您提供部分或全部服务。
        <br />
        <br />
        3.4
        作为注册用户，您有责任妥善保管您的注册账户信息（包括但不限于账号及密码）以保障其安全，并且您应对您的注册账户下的一切行为和活动负责并承担由此产生的一切法律责任。
        <br />
        <br />
        3.5
        您浏览本网站及/或使用本网站提供之服务必须遵守中华人民共和国相关法律法规规定及本协议条款，不得侵犯任何第三方的合法权益，且不得有以下任一行为，否则，我们有权自主判断采取适当的法律行动，包括但不限于暂停或停止向您提供本网站之部分或全部服务、暂停或阻止您的注册账户的登录和/或使用、冻结或注销您的注册账户等。
        <br />
        <br />
        (1)
        将任何有攻击性的、侮辱的、诽谤的、色情的、淫秽的、欺诈的、虚假的、引人误解的内容用作注册信息；
        <br />
        <br />
        (2)
        利用恶意的内容，如病毒、蠕虫、特洛伊木马或其他有破坏性的功能干扰或破坏本网站的正常运营；或
        <br />
        <br />
        (3)
        干扰本网站或篡改或试图查找、扫描或测试以寻找本网站或与本网站相关的系统或网络的漏洞，或违反任何网站安全或认证规定的。
        <br />
        <br />
        四、法律责任及免责
        <br />
        <br />
        4.1
        您明确同意，因您浏览本网站和/或使用本网站服务所引起的一切风险、后果和责任均应由您本人独立承担，我们对此不承担任何责任。
        <br />
        <br />
        4.2
        为方便用户，本网站可能会包含链接至第三方网站/网页的外部链接。对于此类外部链接，我们不保证其准确性和完整性，并且，对于该等外部链接指向的第三方网站/网页及其提供的任何内容、服务或产品，我们不承担任何责任。
        <br />
        <br />
        4.3
        对于因不可抗力或我们不能控制的原因造成的网络服务中断或其它缺陷，我们不承担任何责任。
        <br />
        <br />
        4.4
        任何由于计算机自身问题、黑客攻击、计算机病毒侵入或发作，因政府管制而造成的暂时性关闭等影响提供正常服务等不可抗力而造成的个人信息泄露、丢失、被盗用或被窜改等，我们对此不承担任何责任。
        <br />
        <br />
        4.5
        您同意应使我们和/或我们的关联公司免于遭受任何因您违反本协议条款而导致或产生的主张、诉求、索赔、成本、费用、责任或损失，否则，您同意对我们和/或我们的关联公司进行赔偿，赔偿我们公司与合作公司、关联公司，并使之免受损害。
        <br />
        <br />
        五、知识产权
        <br />
        <br />
        5.1
        我们尊重知识产权并希望您同样尊重知识产权。因此，以下行为是严厉禁止的且您同意将不会：
        <br />
        <br />
        (1)
        复制、翻录、或者向公众公开网站您注册登录本网站后获取的网站的任何部分内容或服务；
        <br />
        <br />
        (2)
        试图破解、反向工程、反向编译、反汇编、修改本网站中的任何内容或软件或者基于本网站的部分或全部内容创作衍生作品；
        <br />
        <br />
        (3) 规避我们、其许可人或第三方为保护本网站上内容而使用的任何技术；
        <br />
        <br />
        (4) 出租、租赁或转售与本网站相关的任何部分；
        <br />
        <br />
        (5)
        向任何第三方披露您的账户的账号、密码或其他注册信息或者使用任何其他人的账号和密码登录使用本网站；
        <br />
        <br />
        (6)
        使用任何远程控制、spider、scraper、深层链接或者其他类似的自动的信息收集或者抽取工具、程序、算法或者方法来进入、获取、复制或监视本网站的全部或部分；以及
        <br />
        <br />
        (7)除了本网站明确允许使用的搜索引擎和搜索代理以及通常使用的第三方网页浏览器（比如微软浏览器）外，使用或者试图使用任何引擎、软件、工具、代理或者任何其他设备或机器（包括但不限于浏览器、Spiders、远程控制、虚拟或者智能代理）来操控或者搜索本
        网站。
        <br />
        <br />
        5.2
        本网站、本网站提供的所有服务及与本网站相关的所有材料，包括但不限于内容、文本、图片、动画、软件、音频和视频文件、文档应为我们和/或其许可人和/或关联方的独家专有财产。与本网站相关的所有材料受国际版权、商标及其他知识产权相关法律的保护。在任何情况下您不得以任何方式披露、公布、分发、再许可、翻译、转载、修改、编辑或复制上述任何材料。
        本网页服务的文字、图片、音频、视频等版权均归本公司享有或本公司已得到相关权利人的合法授权，未经本公司或权利人许可，不得任意复制、转载或用于任何商业目的。
        <br />
        <br />
        5.3
        根据您选择使用的服务类型，我们将授予您有限的、非独家专有的、可撤销的权利，为个人、非商业目的使用该等服务（以下简称“许可”），该许可在您选择的服务的有效期限内有效。本网站中的软件应用程序是被许可给您使用的，并非售卖给您。即使您已在您的设备上安装了该等程序，所有软件应用程序及其副本的所有权认为我们或其许可人所有。
        <br />
        <br />
        5.4
        本网站中涉及的所有的商标、服务标识、商号、标识、域名、图标、标志、品牌名、产品和/或服务名称均为我们和/或其许可人和/或关联方的独家专有财产。上述许可并未授权您使用前述商标、服务标识、商号、标识、域名、图标、标志、品牌名、产品和/或服务名称，无论是用于商业目的还是非商业目的。
        <br />
        <br />
        六、用户隐私
        <br />
        <br />
        为向您提供本网站服务或出于履行本协议需要，我们可处理您的个人信息，例如您本人提供的个人信息或因您使用本网站提供的服务而生成的信息。
        <br />
        <br />
        您同意，在法律允许的范围内，我们可能会向我们在中国境内外的关联方及其他业务合作方分享您的信息。我们已依法为个人信息的跨境传输采取了适当的保护措施。
        <br />
        <br />
        我们仅在为实现收集个人信息的目的所必要的期限或在根据法律规定和市场惯例所确定的时限内保存您的个人信息，但为遵守某项法律义务或为发起、行使或抗辩法律诉求而需要继续留存或已与您沟通达成更长保存期限则另作别论。我们将为直接营销目的保存您的部分个人信息，直至您撤销同意或我们不再需要该等个人信息为止。
        <br />
        <br />
        如果您希望获得一份我们持有的您的个人信息的副本，或者发起投诉，或者要求纠正或删除个人信息，或者限制或拒绝我们处理您的个人信息，或撤销您的同意或要求我们停止为直接营销目的使用您的个人信息，可通过compliance.china@ef.com联系我们。
        <br />
        <br />
        七、附则
        <br />
        <br />
        7.1
        本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。任何与本协议相关争议或纠纷如无法协商解决，任何一方可将争议提交北京国际经济贸易仲裁委员会，依据其届时有效的仲裁规则由一名独任仲裁员在北京进行仲裁。仲裁裁决应为终局裁决，对您和英点商务均具有约束力。
        <br />
        <br />
        7.2
        如本协议中的任何条款全部或部分无效或不具有执行力，不影响其他条款的效力。
        <br />
        <br />
        网站运营方：北京英点商务咨询有限公司
      </Content>
    </MainBlock>
  </Container>
);

import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";

import DocContent from "./page/DocContent";
import DocDispute from "./page/DocDispute";
import DocPG from "./page/DocPg";
import DocSvc from "./page/DocSvc";
import DocTech from "./page/DocTech";
import Login from "./page/Login";
import Register from "./page/Register";
import Member from "./page/Member";
import Payment from "./page/Payment";
import PaymentError from "./page/PaymentError";

import Home from "./App";

const Routes = () => (
  <Router>
    <Route exact path="/" component={Home} />
    <Route path="/doc_content" component={DocContent} />
    <Route path="/doc_dispute" component={DocDispute} />
    <Route path="/doc_pg" component={DocPG} />
    <Route path="/doc_svc" component={DocSvc} />
    <Route path="/doc_tech" component={DocTech} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />

    <Route path="/member" component={Member} />
    <Route path="/payment" component={Payment} />
    <Route path="/payment-error" component={PaymentError} />
  </Router>
);

export default Routes;

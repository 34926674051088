import React, { useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import pgProcessPic from "../assets/pg-process.png";

const Container = styled.div`
  margin-top: 10px;
  font-size: 12px;
  @media screen and (max-width: 767px) {
    margin: 0px 24px 90px 24px;
  }

  @media screen and (min-width: 768px) {
    width: 960px;
    margin: 0 auto;
  }
`;
const Header = styled.div`
  margin-top: 20px;
  font-size: 32px;
  font-weight: bold;

  @media screen and (max-width: 767px) {
    margin-bottom: 35px;
  }

  @media screen and (min-width: 768px) {
    text-align: center;
    margin-bottom: 60px;
  }
`;
const SubHeader = styled.div`
  font-size: 14px;

  @media screen and (max-width: 767px) {
    margin-top: -24px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 768px) {
    margin-top: -50px;
    text-align: center;
    margin-bottom: 60px;
  }
`;
const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-justify: inter-ideograph;
  @media screen and (min-width: 768px) {
    margin: 0px auto 72px auto;
  }
`;
const Content = styled.p`
  white-space: pre-line;
  font-size: 14px;
`;

const engineeringSystem = (
  <>
    <Header>未成年人家长监护工程</Header>
    <SubHeader>-帮助家长防范未成年人沉迷动漫</SubHeader>
    <MainBlock>
      <Content>
        “网络动漫未成年人家长监护工程”是一项由国内网络动漫企业共同发起并参与实施，由中华人民共和国文化部指导，旨在加强家长对未成年人参与网络动漫的监护，引导未成年人健康、绿色参与网络动漫，和谐家庭关系的社会性公益行动。它提供了一种切实可行的方法，一种家长实施监控的管道，使家长纠正部分未成年子女沉迷动漫的行为成为可能。
        <br />
        <br />
        该项社会公益行动充分反映了中国网络动漫行业高度的社会责任感，对未成年用户合法权益的关注及对用实际行动营造和谐社会的愿望。
        <br />
        <br />
        “家长监护机制”是英点商务在这一公益行动中，针对目前未成年人缺乏自控及自律能力，容易陷入沉迷；少数监护人缺少时间照顾未成年人，不能及时监督未成年人动漫时间的现状，而推出的一种可由家长实施监控，纠正部分未成年子女沉迷动漫的保护机制。
        <br />
        <br />
        <br />
        未成年人健康参与网络动漫提示：
        <br />
        <br />
        随着网络在青少年中的普及，未成年人接触网络动漫已经成为普遍现象。为保护未成年人健康参与动漫，在政府进一步加强行业管理的前提下，家长也应当加强监护引导。为此，我们为未成年人参与网络动漫提供以下意见：
        <br />
        <br />
        (1)
        主动控制动漫时间。动漫只是学习、生活的调剂，要积极参与线下的各类活动，并让父母了解自己在网络动漫中的行为和体验。
        <br />
        <br />
        (2)
        不要将动漫当作精神寄托。尤其在现实生活中遇到压力和挫折时，应多与家人朋友交流倾诉，不要只依靠动漫来缓解压力。
        <br />
        <br />
        (3)
        养成积极健康的动漫心态。克服攀比、炫耀、仇恨和报复等心理，避免形成欺凌弱小、抢劫他人等不良网络行为习惯。
        <br />
        <br />
        (4)
        注意保护个人信息。包括个人家庭、朋友身份信息，家庭、学校、单位地址，电话号码等，防范网络陷阱和网络犯罪。
        <br />
        <br />
        <br />
        家长监护服务说明：
        <br />
        <br />
        家长监护系统充分考虑家长的实际需求，当家长发现自己的孩子玩动漫过于沉迷的时候，由家长提供合法的监护人资质证明、动漫名称账号、以及家长对于限制强度的愿望等信息，可对处于孩子动漫沉迷状态的账号采取适当的限制措施，解决未成年人沉迷动漫的不良现象，如限制未成年人每天玩动漫的时间区间和长度，也可以限制只有节假日才可以动漫，或者完全禁止。
        <br />
        <br />
        <br />
        家长监护服务进度查询：
        <br />
        <br />
        如果您已经申请家长监护服务，在服务期间，当您对需要提交的信息、处理结果有疑问，或者其他任何问题，您可以按以下流程联系我们，我们将由专门负责的受理专员为您提供咨询解答服务，或者配合、指导您解决问题。
      </Content>
    </MainBlock>
  </>
);

const serviceApplication = (
  <>
    <Header>未成年人家长监护工程</Header>
    <SubHeader>-帮助家长防范未成年人沉迷动漫</SubHeader>
    <MainBlock>
      <Content style={{ alignSelf: "flex-start" }}>申请流程：</Content>
      <img className="content-image" src={pgProcessPic} alt="" />
      <br />
      <br />
      <Content>
        流程介绍详细说明：
        <br />
        <br />
        监护人提出申请
        <br />
        在监护人发现被监护人有沉溺英点商务网络动漫的情况下，监护人可向英点商务申请发起未成年人家长监护机制。监护人需亲自通过邮寄方式向我司提供有效材料（具体见附件），向英点商务提出未成年人账户监控的申请。在收到邮件后，在判断申请材料完整的情况下，我司即开始启动监护机制审核流程，首先进入疑似账号身份确认期。疑似账号身份确认期（15
        日）
        <br />
        <br />
        在判断申请材料完整的情况下，我司将通过官方邮箱联系疑似账号归属者，告知其在
        15日内将按照监护人需求对其账号进行相关操作，并要求疑似账号归属者提供身份材料以便我司判定其与监护人监护关系；若疑似账号归属者在15日内不能提供有效身份证明或逾期提供，则默认为疑似账号归属者与被监护人身份相符。我司即按照监护人申请要求，对其动漫账号纳入防沉迷系统；
        <br />
        <br />
        若疑似账号归属者在
        15日内提供的身份证明与被监护人相符，我司即按照监护人申请要求，对其动漫账号纳入防沉迷系统；
        <br />
        <br />
        若疑似账号归属者在15日内提供的身份证明与被监护人不符，我司则无法判定其与被监护人的身份关系。在此情况下，为保障用户动漫账号安全，我司将通知监护人通过公安机关出具账号找回协查证明，由我司协助被监护人找回动漫账号后再进行后续操作；
        <br />
        <br />
        <br />
        监护服务申请书：
        <a
          href={`${process.env.PUBLIC_URL}/attachments/附件三 英点商务未成年人家长监护申请书.docx`}
          download="英点商务未成年人家长监护申请书"
        >
          （请至网站下载或查看）
        </a>
        <br />
        <br />
        <br />
        英点商务邮寄地址：
        <br />
        <br />
        收件人：北京英点商务咨询有限公司
        <br />
        地 址：北京市朝阳
        <br />
        区(县)西大望路1号1号楼（温特莱中心写字楼A座）7层706室 邮 编：100026
        <br />
        <br />
        <br />
        注：
        <br />
        (1)请您在快递面单注明申请事项。
        <br />
        (2)请您在身份证复印件上写明您的签名以及联系电话，QQ号，电子邮箱。
        <br />
        (3)请您确认官方邮箱地址为：
        <a href="mailto: compliance.china@ef.com">compliance.china@ef.com</a>
        ，以免受骗上当。
        <br />
        (4)请您注意我司提供该项服务不收取任何费用，以免上当受骗。
        <br />
        <br />
        <br />
        另附附件：
        <br />
        <br />
        <a
          href={`${process.env.PUBLIC_URL}/attachments/附件一 监护人信息表.docx`}
          download="监护人信息表"
        >
          附件一 监护人信息表
        </a>
        <br />
        <a
          href={`${process.env.PUBLIC_URL}/attachments/附件二 被监护人信息表.docx`}
          download="被监护人信息表"
        >
          附件二 被监护人信息表
        </a>
        <br />
        <a
          href={`${process.env.PUBLIC_URL}/attachments/附件三 英点商务未成年人家长监护申请书.docx`}
          download="英点商务未成年人家长监护申请书"
        >
          附件三 英点商务未成年人家长监护申请书
        </a>
        <br />
        <a
          href={`${process.env.PUBLIC_URL}/attachments/附件四 保证书.docx`}
          download="保证书"
        >
          附件四 保证书
        </a>
        <br />
        <a
          href={`${process.env.PUBLIC_URL}/attachments/附件五 授权书.docx`}
          download="授权书"
        >
          附件五 授权书
        </a>
        <br />
        <br />
      </Content>
    </MainBlock>
  </>
);

const tabs = [
  { name: "engineeringSystem", label: "工程体系", content: engineeringSystem },
  { name: "serviceApplication", label: "服务申请", content: serviceApplication }
];

const Tabs = styled.div`
  display: flex;
  margin-top: 24px;
  font-size: 14px;
`;

const Tab = styled.div`
  cursor: pointer;
  font-weight: ${({ selected }) => (selected ? "bold" : "initial")};
  & + & {
    margin-left: 32px;
  }
`;

export default props => {
  const [selectedTab, setSelectedTab] = useState("engineeringSystem");

  const handleTabSelect = useCallback(e => {
    setSelectedTab(e.target.getAttribute("data-name"));
  }, []);

  const selectedTabData = useMemo(
    () => tabs.find(t => t.name === selectedTab),
    [selectedTab]
  );

  return (
    <Container>
      <Tabs>
        {tabs.map(t => (
          <Tab
            key={t.name}
            selected={selectedTab === t.name}
            data-name={t.name}
            onClick={handleTabSelect}
          >
            {t.label}
          </Tab>
        ))}
      </Tabs>
      {selectedTabData.content}
    </Container>
  );
};

import React from 'react';

import Header from '../components/Header'
import MainNavigation from '../components/MainNavigation'
import Register from '../components/Register'
import Footer from '../components/Footer'

export default (props) => (
    <div>
        <Header></Header>
        <MainNavigation></MainNavigation>
        <Register></Register>
        <Footer></Footer>
    </div>
)

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #fafafa;
  padding-bottom: 20px;
  position: relative;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }

  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
`;
const Center = styled.div`
  @media screen and (max-width: 767px) {
    margin: 0px 24px 0px 24px;
    padding-top: 50px;
  }
  @media screen and (min-width: 768px) {
    width: 960px;
    margin: 0 auto;
    padding: 32px 0px 20px 0px;
  }
`;
const Header = styled.div`
  text-align: center;
  text-justify: inter-ideograph;
  font-size: 24px;
  margin-bottom: 20px;
`;

const Content = styled.div`
  margin-top: 18px;
  width: 60ch;
  margin: 0 auto;
`;


export default (props) => (
  <Container>
    <Center>
      <Header>关于我们</Header>

      <Content>
        北京英孚教育科技有限公司，成立于2006-04-04，注册资本为252万人民币，法定代表人为徐晓岭，经营状态为存续，工商注册号为110105009463111，注册地址为北京市平谷区林荫北街13号信息大厦802室-21157（集群注册），经营范围包括技术开发、技术推广、技术转让、技术咨询、技术服务；经济贸易咨询；企业管理；企业形象策划；教育咨询；销售文具用品、工艺美术品、机械设备、日用品、服装、纺织品、体育用品、五金交电、化工产品（不含危险化学品）、玩具、电子产品、计算机、软件及辅助设备、通讯设备；软件开发；设计、制作、代理、发布广告；从事互联网文化活动；出版物零售；广播电视节目制作；经营电信业务。（市场主体依法自主选择经营项目，开展经营活动；从事互联网文化活动、出版物零售、广播电视节目制作、经营电信业务以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。
      </Content>
    </Center>
  </Container>
);

import React from "react";

import Header from "../components/Header";
import HeaderLogin from "../components/HeaderLogin";
import Footer from "../components/Footer";
import styled from "styled-components";

const Container = styled.div`
  max-width: 940px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-top: 20px;
  min-height: 600px;
  color: #f40e0e;
`;
export default (props) => (
  <div>
    <Header></Header>
    <HeaderLogin></HeaderLogin>
    <Container>非常抱歉！支付失败。请稍后再试！</Container>
    <Footer></Footer>
  </div>
);

import React from 'react';

import Header from '../components/Header'
import Footer from '../components/Footer'
import DocDispute from '../components/DocDispute'
import MainNavigation from '../components/MainNavigation'

export default (props) => (
    <div>
        <Header></Header>
        <MainNavigation></MainNavigation>
        <DocDispute></DocDispute>
        <Footer></Footer>
    </div>
)

import React from "react";

import Header from "./components/Header";
import HeaderLogin from "./components/HeaderLogin";
// import ComicVideoList from './components/comic_video_list';
import ComicImageList from "./components/ComicImageList";
import AboutSection from "./components/AboutSection";
import Footer from "./components/Footer";

import { isBeijing } from "./utils/region";

function App() {
  return (
    <div>
      <Header></Header>
      <HeaderLogin></HeaderLogin>
      <ComicImageList></ComicImageList>
      {isBeijing && <AboutSection />}
      <Footer></Footer>
    </div>
  );
}

export default App;

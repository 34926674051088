import ReactModal from "react-modal";
import { useState } from "react";
import styled from "styled-components";

import * as yup from "yup";

import AliPay from "../assets/alipay-brands.svg";
import WeChatPay from "../assets/weixin-brands.svg";
import CreditCard from "../assets/credit-card-solid.svg";

const Content = styled.div`
  position: relative;
  padding: 20px;
  width: 260px;
`;
const CloseButton = styled.div`
  position: absolute;
  top: -15px;
  right: -10px;
  z-index: 100;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-weight: bold;
  cursor: pointer;
`;

const PayButton = styled.div`
  width: fit-content;
  font-weight: bold;
  color: white;
  font-size: 11px;
  border-radius: 12px;
  padding: 4px 16px;
  color: #ff68b5;
  border: 1px solid #ff68b5;

  cursor: pointer;
  &:hover {
    background-color: #ff329b;
    color: white;
  }
`;

const theme = {
  blue: "#009EE8",
};
const FormWrapper = styled.div`
  @media screen and (min-width: 768px) {
    width: 350px;
  }
  margin: 0 auto;
`;
const RelativeWrapper = styled.div`
  position: relative;
`;
const TextWrapper = styled(RelativeWrapper)`
  & > input {
    appearance: none;
    width: 100%;
    height: 40px;
    margin-top: 32px;
    text-indent: 24px;
    border-radius: 8px;
    padding: 0;
    box-shadow: 2px 2px 12px 1px rgba(0, 0, 0, 0.15);
    /* border: 1px solid rgba(25,25,25,0.2); */
    border: none;
    outline: none;
    transition: all 0.2s ease;
    &:focus {
      border-color: ${(props) => props.theme.blue};
    }
    &.error {
      background-color: rgba(242, 101, 82, 0.15);
      border: 1px solid rgba(242, 101, 82, 0.5);
      box-shadow: none;
    }
  }
  & .errmsg {
    text-align: left;
    margin-top: 5px;
    color: #f26552;
  }
`;
const SubmitBtn = styled.button`
  width: 100%;
  appearance: none;
  border: 1px solid #ff327f;
  padding: 7px;
  border-radius: 8px;
  color: #fff;
  background: #f04895;
  letter-spacing: 2px;
  &[disabled] {
    background: rgba(0, 158, 232, 0.4);
    border-color: transparent;
  }
`;
const Image = styled.img`
  position: relative;
  width: 100px;
  padding: 12px;
  height: 100px;
  object-fit: contain;
  margin: 0 auto;
  display: inherit;
`;
const Row = styled.div`
  display: flex;
  gap: 10px;
`;
const Col = styled.div``;

const Step = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px 8px;
  margin: 10px 0;
  cursor: pointer;
`;
const Circle = styled.div`
  margin-right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #b0b0b0;
  &.selected {
    background: #007bff;
    border: 1px solid #abd3ff;
  }
`;
const OptionImage = styled.img`
  margin-right: 6px;
  width: 16px;
  height: 16px;
`;
const StepText = styled.div``;

const schema = yup.object().shape({
  Name: yup.string().required("需要"),
  BankCard: yup.string().required("需要"),
  ExpireDate: yup.string().required("需要"),
  CVC: yup.string().required("需要"),
});

export default (props) => {
  const [isOpen, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [selected, setSelected] = useState("支付宝");

  return (
    <span>
      <ReactModal isOpen={isOpen} onRequestClose={() => setOpen(false)}>
        <Content>
          <Image src={props.charaterUrl} />
          <div>
            <b>价格：</b> 2元
          </div>

          {[
            { value: "支付宝", image: AliPay },
            { value: "微信支付", image: WeChatPay },
            { value: "银行卡", image: CreditCard },
          ].map(({ value, image }) => (
            <Step onClick={() => setSelected(value)}>
              <Circle className={value == selected ? "selected" : ""} />
              <OptionImage src={image} />
              <StepText>{value}</StepText>
            </Step>
          ))}
          {/*
          <FormWrapper>
            <Formik
              initialValues={{
                Name: "",
                BankCard: "",
                ExpireDate: "",
                CVC: "",
              }}
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={(
                values,
                { setSubmitting, setError, setFieldError }
              ) => {
                setSubmitting(true);
                setTimeout(() => {
                  alert("服务器忙");
                  setSubmitting(false);
                }, 2000);
              }}
            >
              {({ isSubmitting, touched, errors, values }) => (
                <Form>
                  <ThemeProvider theme={theme}>
                    <TextWrapper>
                      <Field
                        className={
                          touched["Name"] && errors["Name"] ? "error" : ""
                        }
                        type="text"
                        name="Name"
                        placeholder="请输入名字"
                        autocomplete="name"
                      />
                      <ErrorMessage
                        className="errmsg"
                        name="Name"
                        component="div"
                      />
                    </TextWrapper>

                    <TextWrapper>
                      <Field
                        className={
                          touched["BankCard"] && errors["BankCard"] ? "error" : ""
                        }
                        type="text"
                        name="BankCard"
                        placeholder="请输入银行卡号"
                        autocomplete="cc-name"
                      />
                      <ErrorMessage
                        className="errmsg"
                        name="BankCard"
                        component="div"
                      />
                    </TextWrapper>
                  </ThemeProvider>

                  <Row>
                    <Col>
                      <TextWrapper>
                        <Field
                          className={
                            touched["ExpireDate"] && errors["ExpireDate"]
                              ? "error"
                              : ""
                          }
                          type="text"
                          name="ExpireDate"
                          placeholder="请输入截止日期"
                          autocomplete="cc-none"
                        />
                        <ErrorMessage
                          className="errmsg"
                          name="ExpireDate"
                          component="div"
                        />
                      </TextWrapper>
                    </Col>
                    <Col>
                      <TextWrapper>
                        <Field
                          className={
                            touched["CVC"] && errors["CVC"] ? "error" : ""
                          }
                          type="text"
                          name="CVC"
                          placeholder="请输入CVC"
                          autocomplete="cc-none"
                        />
                        <ErrorMessage
                          className="errmsg"
                          name="CVC"
                          component="div"
                        />
                      </TextWrapper>
                    </Col>
                  </Row>

                  <SubmitBtn
                    id="button_toLeadForm"
                    name="loginBtn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    支付
                  </SubmitBtn>

                  <TextWrapper>
                    <div className="errmsg">{errMsg}</div>
                  </TextWrapper>
                </Form>
              )}
            </Formik>
                        </FormWrapper> */}

          <SubmitBtn id="button_toLeadForm" name="loginBtn" type="submit">
            下一步
          </SubmitBtn>
        </Content>
        <CloseButton onClick={() => setOpen(false)}>X</CloseButton>
      </ReactModal>
      <PayButton
        onClick={() => {
          const open = window.confirm("支付2元可下载该表情");
          if (open) {
            setOpen(true);
          }
        }}
      >
        付费2元即可下载该表情
      </PayButton>
    </span>
  );
};

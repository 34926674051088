import React from "react";
import { Row, Col } from "react-bootstrap";
import styled, { ThemeProvider } from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { PhoneReg } from "../utils/const";
import UserService from "../services/userService";

const Container = styled.div`
  font-size: 14px;

  @media screen and (max-width: 767px) {
    text-align: left;
    margin: 30px 24px 100px 24px;
  }

  @media screen and (min-width: 768px) {
    width: 960px;
    margin: 0px auto 200px auto;
    text-align: center;
  }
`;
const LoginTitle = styled.div`
  font-weight: blod;
  font-size: 32px;
`;
const NotRegister = styled.div`
  text-align: center;
`;
const theme = {
  blue: "#009EE8",
};
const FormWrapper = styled.div`
  @media screen and (min-width: 768px) {
    width: 350px;
  }
  margin: 0 auto;
`;
const RelativeWrapper = styled.div`
  position: relative;
`;
const TextWrapper = styled(RelativeWrapper)`
  & > input {
    appearance: none;
    width: 100%;
    height: 40px;
    margin-top: 32px;
    text-indent: 24px;
    border-radius: 8px;
    padding: 0;
    box-shadow: 2px 2px 12px 1px rgba(0, 0, 0, 0.15);
    /* border: 1px solid rgba(25,25,25,0.2); */
    border: none;
    outline: none;
    transition: all 0.2s ease;
    &:focus {
      border-color: ${(props) => props.theme.blue};
    }
    &.error {
      background-color: rgba(242, 101, 82, 0.15);
      border: 1px solid rgba(242, 101, 82, 0.5);
      box-shadow: none;
    }
  }
  & .errmsg {
    text-align: left;
    margin-top: 5px;
    color: #f26552;
  }
`;
const SubmitBtn = styled.button`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
  appearance: none;
  border: 1px solid #ff327f;
  padding: 7px;
  border-radius: 8px;
  color: #fff;
  background: #f04895;
  letter-spacing: 2px;
  &[disabled] {
    background: rgba(0, 158, 232, 0.4);
    border-color: transparent;
  }
`;
const GetVerCodeBtn = styled.button`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
  appearance: none;
  border: 1px solid #ff327f;
  padding: 7px;
  border-radius: 8px;
  color: #fff;
  background: #f04895;
  letter-spacing: 2px;
  &[disabled] {
    background: rgba(0, 158, 232, 0.4);
    border-color: transparent;
  }
`;

const schema = yup.object().shape({
  MobileTel: yup
    .string()
    .required("请输入正确的手机号码")
    .matches(PhoneReg, "请输入正确的手机号码"),
  Password: yup.string().required("请输入密码"),
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  render() {
    const { isVerDisable, verification, errMsg, errors } = this.state;
    return (
      <Container>
        <LoginTitle>登录</LoginTitle>
        <FormWrapper>
          <Formik
            initialValues={{ MobileTel: "", VerCode: "" }}
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting, setError, setFieldError }) => {
              const mobile = values["MobileTel"];
              const password = values["Password"];

              setSubmitting(true);
              UserService.sendLoginRequest(mobile, password).then(
                (loginRes) => {
                  console.log("start login");
                  console.log(loginRes);
                  setSubmitting(false);
                  if (loginRes.auth) {
                    UserService.saveLoginUser({
                      mobile,
                      token: loginRes.token,
                    });
                    window.location.href = "/";
                    // this.props.history.push(`/`)
                  } else {
                    if (loginRes.error === "No user found.") {
                      this.setState({
                        errMsg: "找不到用户",
                      });
                    } else if (loginRes.error === "Wrong password") {
                      this.setState({
                        errMsg: "密码不对",
                      });
                    } else {
                      this.setState({ MobileTel: "", errMsg: "登录失败" });
                    }
                  }
                }
              );
            }}
          >
            {({ isSubmitting, touched, errors, values }) => (
              <Form>
                <ThemeProvider theme={theme}>
                  <TextWrapper>
                    <Field
                      className={
                        touched["MobileTel"] && errors["MobileTel"]
                          ? "error"
                          : ""
                      }
                      type="text"
                      name="MobileTel"
                      placeholder="请输入手机号"
                      autocomplete="phone"
                    />
                    <ErrorMessage
                      className="errmsg"
                      name="MobileTel"
                      component="div"
                    />
                  </TextWrapper>
                </ThemeProvider>

                <TextWrapper>
                  <Field
                    className={
                      touched["Password"] && errors["Password"] ? "error" : ""
                    }
                    type="password"
                    name="Password"
                    placeholder="请输入密码"
                  />
                  <ErrorMessage
                    className="errmsg"
                    name="Password"
                    component="div"
                  />
                </TextWrapper>

                <SubmitBtn
                  ref={(r) => (this.formik = r)}
                  id="button_toLeadForm"
                  name="loginBtn"
                  type="submit"
                  disabled={isSubmitting}
                >
                  登录
                </SubmitBtn>

                <TextWrapper>
                  <div className="errmsg">{this.state.errMsg}</div>
                </TextWrapper>
              </Form>
            )}
          </Formik>
        </FormWrapper>
        <NotRegister>
          <Link to="/register">还未注册？</Link>
        </NotRegister>
      </Container>
    );
  }
}

export default Login; // withRouter(Login)

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import UserService from "../services/userService";
import { isBeijing } from "../utils/region";

const Container = styled.div`
  font-size: 16px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    height: 4px;
    display: block;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), transparent);
  }
  padding: 10px 0 10px 0;
`;
const PCBlock = styled.div`
  display: -webkit-flex;
  -webkit-flex-direction: row;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 960px;
`;
const MobileBlock = styled.div`
  display: -webkit-flex;
  -webkit-flex-direction: row;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 24px 20px 24px;
`;
const Title = styled.div`
  @media screen and (max-width: 767px) {
    font-size: 13px;
  }

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
  font-weight: blod;
`;
const TitleBlank = styled.div`
  width: auto;
`;
const LoginBlock = styled.div`
  display: -webkit-flex;
  -webkit-flex-direction: row;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin: 0px 24px 20px 90px;
  }
`;
const LoginButton = styled.a`
  color: white;
  border-radius: 16px;
  width: 70px;
  height: 28px;
  background-color: #ff329b;
  margin-right: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  background-image: linear-gradient(hsla(0, 0%, 100%, 0.3), transparent);
  cursor: pointer;
  text-decoration: none !important;
  span {
    color: white;
    text-decoration: none !important;
  }

  &:hover {
    box-shadow: inset 0 0 0 0 rgba(25, 25, 25, 0.4),
      0 4px 12px rgba(127, 25, 77, 0.4), 0 0 0 0 rgba(255, 50, 155, 0.1);
  }
`;
const RegisterButton = styled.a`
  width: 70px;
  height: 28px;
  border-radius: 16px;
  border: solid 1px #ececec;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  span {
    color: #333;
    text-decoration: none !important;
  }

  &:hover {
    box-shadow: inset 0 0 0 0 rgba(25, 25, 25, 0.4),
      0 4px 12px rgba(127, 25, 77, 0.4), 0 0 0 0 rgba(255, 50, 155, 0.1);
  }
`;
const ReverseButton = styled.div``;
const LoginInfoBlock = styled.div`
  margin-right: 20px;
`;
const LogoutBlock = styled.div`
  width: 70px;
  height: 28px;
  border-radius: 16px;
  border: solid 1px #ececec;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  cursor: pointer;
  span {
    color: #333;
    text-decoration: none !important;
  }

  &:hover {
    border: 1px solid #666;
  }
`;

const Links = styled.div`
  display: flex;
`;
const LinkText = styled.a`
  display: block;
  margin-right: 32px;
  line-height: 24px;
  color: #333;
  margin-right: 16px;
  font-weight: 500;
`;
class HeaderLogin extends React.Component {
  constructor() {
    super();

    var userInfo = UserService.getLoginUser();
    console.log(userInfo);
    if (!userInfo) {
      userInfo = "";
    }

    this.state = {
      showMobileLogin: false,
      mobile: userInfo.mobile,
    };
  }

  clickShowMobileLogin() {
    this.setState({ showMobileLogin: !this.state.showMobileLogin });
  }

  logoutUser() {
    UserService.logoutUser();
    this.setState({ mobile: "" });
  }

  render() {
    return (
      <Container>
        <PCBlock className="ef-kids-show-pc">
          <Links>
            <LinkText href="/">网站首页</LinkText>
            <LinkText href="/">关于我们</LinkText>
            <LinkText href="/">联系我们</LinkText>
            <LinkText href="/">漫表情</LinkText>
            <LinkText href="/">付费下载</LinkText>

            {isBeijing && <LinkText href="/#/member">会员文章</LinkText>}
          </Links>

          <TitleBlank></TitleBlank>
          {this.state.mobile && this.state.mobile !== "" ? (
            <LoginBlock>
              <LoginInfoBlock>用户：{this.state.mobile}</LoginInfoBlock>
              <LogoutBlock onClick={() => this.logoutUser()}>注销</LogoutBlock>
            </LoginBlock>
          ) : (
            <LoginBlock>
              <LoginButton href="/#/login">
                <span>登录</span>
              </LoginButton>
              <RegisterButton href="/#/register">
                <span>注册</span>
              </RegisterButton>
            </LoginBlock>
          )}
        </PCBlock>
        <MobileBlock
          className="ef-kids-show-mobile"
          onClick={() => this.clickShowMobileLogin()}
        >
          <Title>动漫作品</Title>
          <TitleBlank></TitleBlank>
          <ReverseButton>
            {this.state.showMobileLogin ? "X" : "^"}
          </ReverseButton>
        </MobileBlock>
        {this.state.showMobileLogin ? (
          this.state.MobileTel && this.state.MobileTel !== "" ? (
            <LoginBlock>
              <LoginInfoBlock>用户：{this.state.MobileTel}</LoginInfoBlock>
              <LogoutBlock onClick={() => this.logoutUser()}>注销</LogoutBlock>
            </LoginBlock>
          ) : (
            <LoginBlock className="ef-kids-show-mobile">
              <LoginButton href="/#/login">
                <span>登录</span>
              </LoginButton>
              <RegisterButton href="/#/register">
                <span>注册</span>
              </RegisterButton>
            </LoginBlock>
          )
        ) : null}
      </Container>
    );
  }
}

export default HeaderLogin;

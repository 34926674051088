import React from "react";

import Header from "../components/Header";
import HeaderLogin from "../components/HeaderLogin";
import Payment from "../components/Payment";

import Footer from "../components/Footer";

export default (props) => (
  <div>
    <Header></Header>
    <HeaderLogin></HeaderLogin>
    <Payment></Payment>
    <Footer></Footer>
  </div>
);

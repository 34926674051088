import React from "react";
import styled, { css } from "styled-components";
import Ben from "../assets/Ben.gif";
import Clora from "../assets/Clora.gif";
import Cooper from "../assets/Cooper.gif";
import Elise from "../assets/Elise.gif";
import Fleet from "../assets/Fleet.gif";
import Kev from "../assets/Kev.gif";
import Mel from "../assets/Mel.gif";
import Roddy from "../assets/Roddy.gif";
import Tom from "../assets/Tom.gif";
import Vic from "../assets/Vic.gif";
import Zap from "../assets/Zap.gif";
import { isBeijing } from "../utils/region";
import PaymentModel from "./PaymentModal";

// OMNI-53056
const characters = [
  {
    title: "Roddy",
    url: Roddy,
    keywords: "古灵精怪的好奇宝宝",
    hobbies: "读书，拼图，画画，探索未知，做白日梦",
    positionAdjustment: {
      left: -65,
    },
  },
  {
    title: "Ben",
    url: Ben,
    keywords: "活泼开朗，善良大方、踏实稳重",
    hobbies: "国际象棋，运动，电子游戏，慈善工作，志愿服务，照顾其他小动物",
    positionAdjustment: {
      left: -80,
    },
  },
  {
    title: "Mel",
    url: Mel,
    keywords: "聪明伶俐，风趣幽默，有点儿笨手笨脚",
    hobbies: "读书，学些，恶作剧，表演，戏剧，唱歌。。。",
    positionAdjustment: {
      left: 25,
    },
  },
  {
    title: "Kev",
    url: Kev,
    keywords: "精力充沛，富有创造力和创新精神",
    hobbies: "游泳，运动，跳舞，研究和发明东西，发现新的办法解决日常的问题。",
    positionAdjustment: {
      left: 40,
    },
  },
  {
    title: "Vic",
    url: Vic,
    keywords: "体贴入微，胆子和声音都很大",
    hobbies: "旅游，探索，吃吃吃，坐过山车，各种新的尝试",
    positionAdjustment: {
      left: 20,
    },
  },
  {
    title: "Zap",
    url: Zap,
    keywords: "古灵精怪的好奇宝宝",
    hobbies: "读书，拼图，画画，探索未知，做白日梦",
    positionAdjustment: {
      left: 60,
    },
  },
  {
    title: "Elise",
    url: Elise,
    desciption: [
      { label: "", value: "她的名字叫Elise。" },
      { label: "最喜欢的食物：", value: "所有健康的食物，她讨厌甜食" },
      { label: "爱好：", value: "体操和户外运动" },
      { label: "性格：", value: "活泼好动，追求完美，无所畏惧，有包容心" },
    ],
    positionAdjustment: {
      left: -20,
    },
  },
  {
    title: "Fleet",
    desciption: [
      { label: "最喜欢的动物：", value: "狗（特别是Cooper）" },
      { label: "爱好：", value: "科学实验，电子产品" },
      {
        label: "性格：",
        value:
          "好奇心强，聪明好学，缺乏耐心，喜欢解决问题，记忆力差，喜欢尝试新鲜食物",
      },
    ],
    url: Fleet,
  },
  {
    title: "Tom",
    desciption: [
      { label: "", value: "他的名字叫Tom。" },
      { label: "最喜欢的食物：", value: "他喜欢蛋糕，讨厌沙拉" },
      { label: "爱好：", value: "他喜欢睡懒觉" },
      {
        label: "性格：",
        value:
          "爱幻想，鲁莽，做事没条理，调皮吵闹，自私，自以为是，但是有时会关心他的朋友。",
      },
      { label: "其他：", value: "他也许会成为一个超级英雄" },
    ],
    url: Tom,
  },
  {
    title: "Clora",
    desciption: [
      {
        label: "爱好：",
        value: "她是个艺术家，她喜欢画画，摄影，唱歌",
      },
      { label: "性格：", value: "叛逆，缺乏耐心，有创意" },
      {
        label: "其他：",
        value:
          "她是一棵树！她长在森林里，一辈子只能望着同一个方向。她从小就希望能逃出森林，去看看外面的世界。",
      },
    ],
    url: Clora,
  },
  {
    title: "Cooper",
    desciption: [
      { label: "", value: "他的名字叫Cooper。" },
      { label: "最喜欢的活动：", value: "咬沙发垫，衣服和肉" },
      { label: "爱好：", value: "他喜欢每周四早上去Sundial 公园跑步" },
      {
        label: "性格：",
        value:
          "Cooper小时候只是只普通的小猎狗。他不会说话但很能干。他会跑，叫，有时候也会惹麻烦。",
      },
      { label: "其他：", value: "Cooper 吃骨头就会长大" },
    ],
    url: Cooper,
  },
];

const MainWrapper = styled.div`
  max-width: 940px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  justify-content: center;
`;

const Character = styled.div`
  width: calc(33% - 30px);
  min-width: 280px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 2px solid #ddd;
  border-radius: 4px;
  margin: 15px;
  background: #fafafa;
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  padding: 12px;
  height: 240px;
  object-fit: contain;

  ${({ positionAdjustment }) =>
    positionAdjustment
      ? css`
          left: ${positionAdjustment.left || 0}px;
        `
      : ""}
`;

const Footer = styled.div`
  background: white;
  margin: 0 1px;

  display: flex;
  flex-direction: column;
  border-top: 1px solid #ddd;
  flex-grow: 1;
`;
const Title = styled.div`
  padding-top: 20px;
  padding-bottom: 4px;
  text-align: center;
  font-weight: bold;
  color: #191919;
  font-size: 18px;
`;

const Desciption = styled.div`
  color: #191919;
  font-size: 14px;
  width: fit-content;
  border-radius: 7px;
  align-self: center;
  background: white;

  padding: 0 20px;

  width: 100%;
  margin: 6px 0px;
`;
const DesciptionValue = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const Label = styled.div`
  font-weight: bold;
  flex-shrink: 0;
`;
const Value = styled.div``;

const FooterButton = styled.div`
  width: 100%;
  padding: 12px 4px;
  text-align: center;
  align-self: center;
  margin-top: auto;
  display: flex;
  justify-content: center;
`;

const PayButton = styled.div`
  width: fit-content;
  font-weight: bold;
  color: white;
  font-size: 11px;
  border-radius: 12px;
  padding: 4px 16px;
  color: #ff68b5;
  border: 1px solid #ff68b5;

  cursor: pointer;
  &:hover {
    background-color: #ff329b;
    color: white;
  }
`;

const Header = styled.div`
  text-align: center;
  width: 100%;
  font-size: 48px;
  font-weight: 700;
  padding: 20px;
`;
const HeaderImageWrapper = styled.div`
  display: flex;
  max-width: 960px;
  margin: 0 auto;
  padding-bottom: 20px;
`;
const HeaderImage = styled.img`
  margin-left: auto;
`;

function ComicImageList({ className }) {
  return (
    <div className={className}>
      <HeaderImageWrapper>
        <HeaderImage
          src="//storyblok-cdn.ef.com.cn/f/9/x/eb97ff6f6a/city-top-cubes.svg"
          data-src="//storyblok-cdn.ef.com.cn/f/9/x/eb97ff6f6a/city-top-cubes.svg"
          data-sizes="auto"
          sizes="707px"
        ></HeaderImage>
      </HeaderImageWrapper>
      <MainWrapper>
        {characters.map((c, i) => (
          <Character key={c.url}>
            <Image src={c.url} positionAdjustment={c.positionAdjustment} />
            <Footer>
              <Title>{c.title}</Title>
              <Desciption>
                {c.desciption &&
                  c.desciption.map((d) => (
                    <DesciptionValue key={`${d.label}-${d.value}`}>
                      <Label>{d.label || ""}</Label>
                      <Value>{d.value || ""}</Value>
                    </DesciptionValue>
                  ))}
                {!c.desciption && (
                  <>
                    <DesciptionValue>
                      <Label>关键词：</Label>
                      <Value>{c.keywords || ""}</Value>
                    </DesciptionValue>
                    <DesciptionValue>
                      <Label>我的爱好：</Label>
                      <Value>{c.hobbies || ""}</Value>
                    </DesciptionValue>
                  </>
                )}
              </Desciption>
              <FooterButton>
                {!isBeijing && <PaymentModel charaterUrl={c.url} />}
              </FooterButton>
            </Footer>
          </Character>
        ))}
      </MainWrapper>
    </div>
  );
}

ComicImageList.propTypes = {};

export default ComicImageList;

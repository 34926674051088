import Cover1 from '../assets/1.png'
import Cover2 from '../assets/2.png'
import Cover3 from '../assets/3.png'
import Cover4 from '../assets/4.png'
import Cover5 from '../assets/5.png'
import Cover6 from '../assets/6.png'
import Cover7 from '../assets/7.png'
import Cover8 from '../assets/8.png'
import Cover9 from '../assets/9.png'
import Cover10 from '../assets/10.png'
import Cover11 from '../assets/11.png'
import Cover12 from '../assets/12.png'
import Cover13 from '../assets/13.png'
import Cover14 from '../assets/14.png'
import Cover15 from '../assets/15.png'
import Cover16 from '../assets/16.png'
import Cover17 from '../assets/17.png'
import Cover18 from '../assets/18.png'

const PhoneReg = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/

const VideoDomain = 'http://www.bj-englishtown.com/video/flv'

const VideoDataList = [
    {
        title: 'ABC字母歌 ABC Alphabet Song',
        cover: Cover1,
        video: `${VideoDomain}/1.flv`
    },
    {
        title: "趣味童谣之快乐歌  If You're Happy Clap Your Hands",
        cover: Cover2,
        video: `${VideoDomain}/2.flv`
    },
    {
        title: '趣味童谣之薄饼时间 Pancake Time Chant',
        cover: Cover3,
        video: `${VideoDomain}/3.flv`
    },
    {
        title: '趣味童谣之职业歌 The Job Song',
        cover: Cover4,
        video: `${VideoDomain}/4.flv`
    },
    {
        title: '趣味童谣之彩虹歌 The Rainbow Song',
        cover: Cover5,
        video: `${VideoDomain}/5.flv`
    },
    {
        title: '趣味童谣之做事之道 This Is The Way We Do',
        cover: Cover6,
        video: `${VideoDomain}/6.flv`
    },
    {
        title: '大聊天气 Comment about the weather',
        cover: Cover7,
        video:  `${VideoDomain}/7.flv`
    },
    {
        title: '我要坐飞机 How to check in at the airport',
        cover: Cover8,
        video: `${VideoDomain}/8.flv`
    },
    {
        title: '同学帮帮我 How to borrow something from classmate',
        cover: Cover9,
        video: `${VideoDomain}/9.flv`
    },
    {
        title: '我们和好吧 How to make an apology',
        cover: Cover10,
        video: `${VideoDomain}/10.flv`
    },
    {
        title: '字母童谣之B Phonics video project_B Song',
        cover: Cover11,
        video: `${VideoDomain}/11.flv`
    },
    {
        title: '字母童谣之E Phonics video project_E Song',
        cover: Cover12,
        video: `${VideoDomain}/12.flv`
    },
    {
        title: '字母童谣之F Phonics video project_F Song',
        cover: Cover13,
        video: `${VideoDomain}/13.flv`
    },
    {
        title: '字母童谣之H Phonics video project_H Song',
        cover: Cover14,
        video: `${VideoDomain}/14.flv`
    },
    {
        title: '字母童谣之N Phonics video project_N Song',
        cover: Cover15,
        video: `${VideoDomain}/15.flv`
    },
    {
        title: '字母童谣之P Phonics video project_P Song',
        cover: Cover16,
        video: `${VideoDomain}/16.flv`
    },
    {
        title: '字母童谣之R Phonics video project_R Song',
        cover: Cover17,
        video: `${VideoDomain}/17.flv`
    },
    {
        title: '字母童谣之T Phonics video project_T Song',
        cover: Cover18,
        video: `${VideoDomain}/18.flv`
    }
]

export { PhoneReg, VideoDataList }
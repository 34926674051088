import React from "react";

import Header from "../components/Header";
import HeaderLogin from "../components/HeaderLogin";
import MainNavigation from "../components/MainNavigation";
import ArticleList from "../components/ArticleList";

import Footer from "../components/Footer";

export default (props) => (
  <div>
    <Header></Header>
    <HeaderLogin></HeaderLogin>
    <ArticleList></ArticleList>
    <Footer></Footer>
  </div>
);

import React from "react";
import styled from "styled-components";

import PayQR from "../assets/payQR.png";

const Container = styled.div`
  max-width: 940px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  justify-content: center;
  margin-top: 20px;
`;

const QrCode = styled.img`
  height: 600px;
`;

export default (props) => (
  <Container>
    <QrCode src={PayQR} />
  </Container>
);

import React from "react";
import { Row, Col } from "react-bootstrap";
import styled, { ThemeProvider } from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
// import { withRouter } from "react-router-dom"

import { PhoneReg } from "../utils/const";
import UserService from "../services/userService";

const Container = styled.div`
  font-size: 14px;

  @media screen and (max-width: 767px) {
    text-align: left;
    margin: 30px 24px 100px 24px;
  }
  @media screen and (min-width: 768px) {
    width: 960px;
    margin: 0px auto 200px auto;
    text-align: center;
  }
`;
const LoginTitle = styled.div`
  font-weight: blod;
  font-size: 32px;
`;
const NotRegister = styled.div`
  text-align: center;
`;
const theme = {
  blue: "#009EE8",
};
const FormWrapper = styled.div`
  @media screen and (min-width: 768px) {
    width: 350px;
  }
  margin: 0 auto;
`;
const RelativeWrapper = styled.div`
  position: relative;
`;
const TextWrapper = styled(RelativeWrapper)`
  & > input {
    appearance: none;
    width: 100%;
    height: 40px;
    margin-top: 32px;
    text-indent: 24px;
    border-radius: 8px;
    padding: 0;
    box-shadow: 2px 2px 12px 1px rgba(0, 0, 0, 0.15);
    /* border: 1px solid rgba(25,25,25,0.2); */
    border: none;
    outline: none;
    transition: all 0.2s ease;
    &:focus {
      border-color: ${(props) => props.theme.blue};
    }
    &.error {
      background-color: rgba(242, 101, 82, 0.15);
      border: 1px solid rgba(242, 101, 82, 0.5);
      box-shadow: none;
    }
  }
  & .errmsg {
    text-align: left;
    margin-top: 5px;
    color: #f26552;
  }
`;
const SubmitBtn = styled.button`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
  appearance: none;
  border: 1px solid #ff327f;
  padding: 7px;
  border-radius: 8px;
  color: #fff;
  background: #f04895;
  letter-spacing: 2px;
  &[disabled] {
    background: rgba(0, 158, 232, 0.4);
    border-color: transparent;
  }
`;
const GetVerCodeBtn = styled.button`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
  appearance: none;
  border: 1px solid #ff327f;
  padding: 7px;
  border-radius: 8px;
  color: #fff;
  background: #f04895;
  letter-spacing: 2px;
  &[disabled] {
    background: rgba(0, 158, 232, 0.4);
    border-color: transparent;
  }
`;

const schema = yup.object().shape({
  MobileTel: yup
    .string()
    .required("请输入正确的手机号码")
    .matches(PhoneReg, "请输入正确的手机号码"),
  Password: yup.string().required("请输入密码"),
  PasswordConfirm: yup
    .string()
    .required("确认密码")
    .oneOf([yup.ref("Password")], "密码不匹配"),
});

const verificationSchema = schema;

class register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isVerDisable, verification, errMsg, errors } = this.state;
    return (
      <Container>
        <LoginTitle>注册</LoginTitle>
        <FormWrapper>
          <Formik
            initialValues={{
              MobileTel: "",
              Password: "",
              PasswordConfirm: "",
            }}
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              const mobile = values["MobileTel"];
              const password = values["Password"];

              UserService.sendRegisterRequest(mobile, password).then(
                (registerRes) => {
                  console.log("start register");
                  console.log(registerRes);
                  setSubmitting(false);
                  if (registerRes.auth) {
                    UserService.saveLoginUser({
                      mobile,
                      token: registerRes.token,
                    });
                    window.location.href = "/";
                    //  this.props.history.push(`/`)
                  } else {
                    alert("注册失败");
                  }
                }
              );
            }}
          >
            {({ isSubmitting, touched, errors, values }) => (
              <Form>
                <ThemeProvider theme={theme}>
                  <TextWrapper>
                    <Field
                      className={
                        touched["MobileTel"] && errors["MobileTel"]
                          ? "error"
                          : ""
                      }
                      type="phone"
                      name="MobileTel"
                      placeholder="请输入手机号码"
                    />
                    <ErrorMessage
                      className="errmsg"
                      name="MobileTel"
                      component="div"
                    />
                  </TextWrapper>
                </ThemeProvider>

                <ThemeProvider theme={theme}>
                  <TextWrapper>
                    <Field
                      className={
                        touched["Password"] && errors["Password"] ? "error" : ""
                      }
                      type="password"
                      name="Password"
                      placeholder="请输入密码"
                    />
                    <ErrorMessage
                      className="errmsg"
                      name="Password"
                      component="div"
                    />
                  </TextWrapper>
                </ThemeProvider>

                <ThemeProvider theme={theme}>
                  <TextWrapper>
                    <Field
                      className={
                        touched["PasswordConfirm"] && errors["PasswordConfirm"]
                          ? "error"
                          : ""
                      }
                      type="password"
                      name="PasswordConfirm"
                      placeholder="确认密码"
                    />
                    <ErrorMessage
                      className="errmsg"
                      name="PasswordConfirm"
                      component="div"
                    />
                  </TextWrapper>
                </ThemeProvider>
                <SubmitBtn
                  id="button_toLeadForm"
                  ref={(r) => (this.formik = r)}
                  name="registerBtn"
                  type="submit"
                  disabled={isSubmitting}
                >
                  注册
                </SubmitBtn>
                <TextWrapper>
                  <div className="errmsg">{this.state.errMsg}</div>
                </TextWrapper>
              </Form>
            )}
          </Formik>
        </FormWrapper>
        <NotRegister>
          <Link to="/login">已注册？ 立即登录&nbsp;&nbsp;&nbsp;&nbsp;></Link>
        </NotRegister>
      </Container>
    );
  }
}

export default register;

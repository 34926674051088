import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    margin-top: 10px;
    font-size: 12px;
    @media screen and (max-width: 767px) {
        margin:0px 24px 90px 24px;
    }
    
    @media screen and (min-width: 768px) {
        width: 960px;
        margin: 0 auto;
    }
`
const Header = styled.div`
    margin-top: 20px;
    font-size: 32px;
    font-weight: bold;

@media screen and (max-width: 767px) {
    margin-bottom: 35px;
}

@media screen and (min-width: 768px) {
    text-align: center;
    margin-bottom: 60px;
}

`
const MainBlock = styled.div`
text-align: Justify;
text-justify: inter-ideograph; 
@media screen and (min-width: 768px) {
    margin:0px auto 72px auto;
}
`
const Content = styled.p`
    margin-bottom: 10px;
    font-size: 14px;
`

export default (props) => (
    <Container>
        <Header>技术安全保障措施</Header>
        <MainBlock>
            <Content>一、网站安全保障措施</Content>
            <Content>1、网站服务器和其他计算机之间设置经公安部门认证的防火墙，并与专业网络安全公司合作，做好安全策略，拒绝外来的恶意攻击，保障网站正常运行。</Content>
            <Content>2、在网站的服务器及工作站上安装正版的防病毒软件，对计算机病毒、有害电子邮件有整套的防范措施，防止有害信息对网站系统的干扰和破坏。</Content>
            <Content>3、做好生产日志的留存。网站具有保护60天以上的系统运行日志和用户使用日志记录功能，内容包括IP地址及使用情况，主页维护者、邮箱使用者和对应的IP地址情况等。</Content>
            <Content>4、交互式栏目具备有IP地址、身份登记和识别确认功能，对没有合法手续和不具备条件的电子公告服务立即关闭。</Content>
            <Content>5、网站信息服务系统建立双机热备份机制，一旦主系统遇到故障或受到攻击导致不能正常运行，保证备用系统能及时替换主系统提供服务。</Content>
            <Content>6、关闭网站系统中暂不使用的服务功能，及相关端口，并及时用不定修复系统漏洞，定期查杀病毒。</Content>
            <Content>7、服务器平时处于锁定状态，并保管好登录密码；后台管理界面设置超级用户名及密码，并绑定IP，以防他人登入。</Content>
            <Content>8、网站提供集中式权限管理，针对不同的应用系统、终端、操作人员，由网站系统管理员设置共享数据库信息的访问权限，并设置相应密码及口令。不同的操作人员设定不同的用户名，且定期更换，严禁操作人员泄露自己的口令。对操作人员的权限严格按照岗位职责设定，并由网站系统管理员定期检查操作人员权限。</Content>
            <Content>9、公司机房按照电信机房标准建设，内有必备的独立UPS不间断电源、高灵敏度的烟雾探测系统和消防系统，定期进行电力、防火、防潮、防磁和防鼠检查。</Content>
            <Content>二、信息安全保密管理制度</Content>
            <Content>1、为防止病毒造成严重后果，对外来光盘、软件要严格管理，原则上不允许外来光盘、软件在公司局域网计算机上使用。确因工作需要使用的，事先必须进行防（杀）毒处理，证实无病毒感染后，方可使用。</Content>
            <Content>2、接入网络的计算机严禁将计算机设定为网络共享，严禁将机内文件设定为网络共享文件。</Content>
            <Content>3、为防止黑客攻击和网络病毒的侵袭，接入网络的计算机一律安装杀毒软件，并要定时对杀毒软件进行升级。</Content>
            <Content>4、严禁将材料存放在网络硬盘上。</Content>
            <Content>5、严禁将涉密办公计算机擅自连接国际互联网。</Content>
            <Content>6、保密级别在秘密以下的材料可通过电子信箱传递和报送，严禁保密级别在秘密以上的材料通过电子信箱、QQ等网上传递和报送</Content>
            <Content>7、涉密计算机严禁直接或间接连接国际互联网和其他公共信息网络，必须实行物理隔离。</Content>
            <Content>8、在与国际互联网相连的信息设备上不得储存、处理、和传输任何涉密信息。</Content>
            <Content>9、坚持“谁上网，谁负责”的原则，各部门要有一名领导负责此项工作，信息上网必须经过领导严格审查，并经主管领导批准。</Content>
            <Content>10、加强对上网人员的保密意识教育，提高上网人员保密观念，增强防范意识，自觉执行保密规定。</Content>
            <Content>11、涉密人员在其它场所上，要提高保密意识，不得在聊天室、电子公告系统、网络新闻上发布、谈论和传播国家秘密信息。使用电子函件进行网上信息交流，应当遵守国家保密规定，不得利用电子函件传递、转发或抄送国家秘密信息。</Content>
            <Content>三、用户信息安全管理制度</Content>
            <Content>1、我公司郑重承诺尊重并保护用户的个人隐私，除了在与用户签署的隐私政策和网站服务条款以及其他公布的准则规定的情况下，未经用户授权我公司不会随意公布与用户个人身份有关的资料，除非有法律或程序要求。</Content>
            <Content>2、所有用户信息将得到本公司网站系统的安全保存，并在和用户签署的协议规定时间内保证不会丢失;</Content>
            <Content>3、严格遵守网站用户账号使用登记和操作权限管理制度，对用户信息专人管理，严格保密，未经允许不得向他人泄露。</Content>
            <Content>4、公司定期对相关人员进行网络信息安全培训并进行考核，使员工能够充分认识到网络安全的重要性，严格遵守相应规章制度。</Content>
        </MainBlock>
    </Container>
)
let host = window.location.host;

let isHainan = false;
if (host === "localhost:3000") {
  let params = new URL(document.location).searchParams;
  console.log(params);
  isHainan = !!params.get("hainan");
} else {
  isHainan = host === "www.english1.cn";
}

const isBeijing = !isHainan;

export { isHainan, isBeijing };

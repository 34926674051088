import React from 'react'
import styled from 'styled-components'
import { Link } from "react-router-dom";

const Container = styled.div`
    margin-top: 20px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 767px) {
        margin-left: 24px;
    }
    
    @media screen and (min-width: 768px) {
        width: 960px;
    }
`

export default (props) => (
    <Container>
        <Link className='ef-kids-show-pc' to='/'>
            首页
        </Link>
        <Link className='ef-kids-show-mobile' to='/'>
            动漫作品
        </Link>
    </Container>
)
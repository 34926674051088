import React from "react";
import styled from "styled-components";
import processPic from "../assets/process.png";

const Container = styled.div`
  margin-top: 10px;
  font-size: 12px;
  @media screen and (max-width: 767px) {
    margin: 0px 24px 90px 24px;
  }

  @media screen and (min-width: 768px) {
    width: 960px;
    margin: 0 auto;
  }
`;
const Header = styled.div`
  margin-top: 20px;
  font-size: 32px;
  font-weight: bold;

  @media screen and (max-width: 767px) {
    margin-bottom: 35px;
  }

  @media screen and (min-width: 768px) {
    text-align: center;
    margin-bottom: 60px;
  }
`;
const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-justify: inter-ideograph;
  @media screen and (min-width: 768px) {
    margin: 0px auto 72px auto;
  }
`;
const Content = styled.p`
  white-space: pre-line;
  font-size: 14px;
`;

export default (props) => (
  <Container>
    <Header>内容自审制度</Header>
    <MainBlock>
      <Content>
        为规范北京英点商务咨询有限公司（“英点商务”）网络文化动漫及服务内容的企业自审工作，明确英点商务开展相关工作的职责、流程、标准以及责任追究办法。根据《网络文化经营单位内容自审管理办法》（文市发〔2013〕39号）的相关要求特制订本制度,具体内容如下：
        <br />
        <br />
        一、审核制度
        <br />
        <br />
        1.
        英点商务对作品及读者负责，对动漫采取先审核制度，凡新创立之作品，均需要先通过审核后，方可在网站显示。
        2.
        对动漫内图片的修改、作品信息的变更，英点商务采用后审核制度，作品相关修改即时生效，但编辑在审核时如发现出现违禁不妥之内容，亦将删除及/或退回。
        <br />
        <br />
        二、审核流程
        <br />
        <br />
        审核时间：工作日上午9:00至18:00，周末12:00-18:00，其余时间不定时审核。
        <br />
        <br />
        1. 建立专组人员进行内容的审查，审查要求如下：
        <br />
        <br />
        (1)
        审核的广度和深度：审核涉及的面较广，不仅包括网络自身的内容，还要包括网络内容涉及到的内容。因为要想真正起作用，不能只对信息系统的“皮毛”进行审计，否则就达不到真正安全的效力。
        <br />
        <br />
        (2)
        审核与更新：英点商务保证对包括但不限于与播放的相关内容、页面内容进行有效审核。在发现有害内容时按有关规定及时向有关部门汇报，并从技术上予以保证，包括有害信息的内容、发现时间、发现来源。
        <br />
        <br />
        (3)
        审核标准：英点商务不允许用户在英点商务网页上或利用英点商务提供的各种服务制作、复制、发布、传播含有根据《网络文化经营单位内容自审管理办法》禁止的内容的信息，一旦发现该类信息，英点商务将按规定进行处理。
        <br />
        <br />
        2.
        设立专项检查监督人员，配合专项审查组人员进行信息内容的审核、检查、管理，确保动漫信息内容的安全、健康。
        <br />
        <br />
        3.
        在审查组内，实行专人专责制度，由组长将每月任务分配于各位组员，每位组员承担相应的审查任务，以便在第一时间内找到相关问题的原因。这种责任落实的制度也可以让组员自觉地形成审核内容的良好习惯,逐渐形成英点商务的良好工作文化传统。
        <br />
        <br />
        4.
        英点商务严格按照《互联网文化管理暂行规定》，在网站显著位置就网络动漫的内容和适用人群制定警示说明。
        <br />
        <br />
        5.
        英点商务已对即将运营的相关动漫从道德、健康、智趣方面严格把关。英点商务了解到知识性是很重要的因素，希望用户既能从动漫中找到快乐，又能学到新的知识。
        <br />
        <br />
        6.
        英点商务将严格按照《互联网文化管理暂行规定》、《文化部关于实施&lt;互联网文化管理暂行规定&gt;有关问题的通知》的相关要求来运营并完善改进自己的动漫服务。
        <br />
        <br />
        7.
        英点商务将穷尽所有力量追求网络动漫的文化内涵和意义，不断责问作为一家动漫英点商务所肩负的道德责任是什么，我们旨在为用户提供优质画面、优质内容、快乐愉悦的动漫而努力。
        <br />
        <br />
        三、人员配备
        <br />
        <br />
        英点商务设立专项检查监督人员，配合专项审查组人员进行信息内容的审核、检查、管理，确保信息内容的安全、健康、富有阅读性。
        <br />
        专项审查组成员名单
        <br />
        组长（总负责人）：李晓莺
        <br />
        成员：金莉莎、李潇潇
        <br />
        对外联系人：金莉莎，联系方式：compliance.china@ef.com
        <br />
        网站整体审核人员：李晓莺，联系方式：compliance.china@ef.com
        <br />
        用户信息及动漫内容审核：金莉莎
        <br />
        监督人员：胡兴豪，联系方式：compliance.china@ef.com
        <br />
        <br />
        <br />
        四、审查标准
        <br />
        <br />
        1.
        作品内容、描述不得有明显的色情及敏感政治、宗教话题，带恶意攻击或其它《互联网文化管理暂行规定》、《文化部关于实施&lt;互联网文化管理暂行规定&gt;有关问题的通知》中禁止发布及/或传播的内容，此类作品将一律被删除。
        <br />
        <br />
        2.
        作品决对不含有诱发未成年人模仿违反社会公德的行为和违法犯罪的行为的内容，以及恐怖、残酷等妨害未成年人身心健康的内容，为广大用户提供一个健康、绿色的休闲动漫平台。
        <br />
        <br />
        3.
        必需为故事动漫，重视故事性及内容性。网站暂不接受单幅插画，单幅幽默讽刺动漫等形式。
        <br />
        <br />
        4. 侵犯他人版权的
        <br />
        <br />
        4.1 无版权的动漫、版权不清晰的动漫，版权存在争议的动漫禁止代理运营；
        <br />
        <br />
        4.2 侵犯他人商标权、著作权的；
        <br />
        <br />
        5. 侵犯用户权益的
        <br />
        <br />
        5.1 动漫和平台注册是否侵犯用户隐私权、名誉权、肖像权等相关权益；
        <br />
        <br />
        5.2 保证动漫用户在动漫中的合法权益。
        <br />
        <br />
        6.
        动漫推广过程中有无违规内容、图片、视频；推广和宣传中不含有不符文化部《网络文化经营单位内容自审管理办法》的要求的内容。
        <br />
        <br />
        7. 备份重要数据库，保存用户注册信息、维护日志等信息。
        <br />
        <br />
        8. 有法律、行政法规和国家规定禁止的其他内容的。
        <br />
        <br />
        五、审查流程
        <br />
        <br />
        1.
        对英点商务自主创作的动漫，在创作阶段对故事背景、美术素材等进行初步的筛查；
        <br />
        <br />
        2.
        自审人员在作品上传网页之前，依据内容审查的相关规定，对动漫及其服务内容（包括宣传推广和活动策划）进行审查，对违法违规的内容进行记录，并签发初审意见；
        <br />
        <br />
        3. 对初审有问题的动漫，退回创作部门进行修改，并对修改后的内容进行复查；
        <br />
        <br />
        4. 对内容的合法合规性不能准确判断的，应向英点商务上层领导申请行政指导；
        <br />
        <br />
        5. 对复查仍有问题的，应按照上述第2-4项规定的内容重新进行审核；
        <br />
        <br />
        6.
        在动漫上传前，对动漫、动漫网站进行审查，审查内容包括证照使用、实名注册、实名信息补填、用户协议必备条款、适龄提示、家长监护工程等内容，合格后方可公测；
        <br />
        <br />
        7.
        对审查完成的动漫，自审人员提出同意进行上传的意见并签字确认，上交内容审查管理工作的主要负责人；
        <br />
        <br />
        8.
        内容审查管理工作的主要负责人签字确认后，根据规定要求，向文化行政管理部门提交动漫备案（如有）；
        <br />
        <br />
        9.
        日常对动漫和服务内容进行监督检查，包括动漫版本更新后的内容、动漫网站、宣传推广和活动策划等各个方面和环节，发现问题提交检查意见，报本企业内容审查管理工作主要负责人；
        <br />
        <br />
        10. 自审人员的所有审查意见应归档留存，保存时间不少于两年；
        <br />
        <br />
        六、有问题的文化产品处理方式及突发情况应急方案
        <br />
        <br />
        为确保发生网络安全问题时各项应急工作高效、有序地进行，最大限度地减少损失，根据互联网网络安全相关条例及上级相关部门文件精神，结合我网站工作实际，特制定本预案。
        <br />
        <br />
        1.
        网站突发事件包括服务器、网络、交换设备等硬件故障和人为故障，包括内部人为技术故障、内部人为责任故障，还包括外部原因如电信网络故障、网络黑客破坏等。
        <br />
        <br />
        2.
        不论何种原因引起了故障英点商务将采取一切必要手段，组织各方面力量全面进行网络安全事故处理工作，把不良影响与损失降到最低点。
        <br />
        <br />
        3.
        调动一切积极因素，平时做好网络安全故障防范，全面保证和促进网络安全稳定地运行。
        <br />
        <br />
        4. 各级突发事件的处理预案
        <br />
        <br />
        4.1 网站不良信息事故处理预案
        <br />
        <br />
        (1)
        一旦发现网站上出现不良信息（或者被黑客攻击修改了网页），立刻关闭网站。
        <br />
        <br />
        (2)
        备份不良信息出现的目录、备份不良信息出现时间前后一个星期内的HTTP连接日志、备份防火墙中不良信息出现时间前后一个星期内的网络连接日志。
        <br />
        <br />
        (3) 打印不良信息页面留存。
        <br />
        <br />
        (4) 完全隔离出现不良信息的目录，使其不能再被访问。
        <br />
        <br />
        (5)
        删除不良信息，并清查整个网站所有内容，确保没有任何不良信息，重新开通网站服务，并测试网站运行。
        <br />
        <br />
        (6)
        修改该目录名，对该目录进行安全性检测，升级安全级别，升级程序，去除不安全隐患，关闭不安全栏目，重新开放该目录的网络连接，并进行测试，正常后，重新修改该目录的上级链接。
        <br />
        <br />
        (7) 全面查对HTTP日志，防火墙网络连接日志，确定该不良信息的源IP地址。
        <br />
        <br />
        (8)
        如果不良信息是反党言论或邪教组织所为，从事故一发生到处理事件的整个过程，必须保持向上级汇报、解释此次事故的发生情况、发生原因、处理过程。
        <br />
        <br />
        4.2 网络恶意攻击事故处理预案
        <br />
        <br />
        (1)
        发现出现网络恶意攻击，立刻确定受攻击的设备有哪些；影响范围有多大。并迅速推断出此次攻击的最坏结果，判断是否需要紧急切断网服务器的网络连接，以保护重要数据及信息。
        <br />
        <br />
        (2) 重新启动该电脑所连接的网络设备，直至完全恢复网络通信。
        <br />
        <br />
        (3)
        对该电脑进行分析，清除所有病毒、恶意程序、木马程序以及垃圾文件，测试运行该电脑5小时以上，并同时进行监控，无问题后归还该电脑。
        <br />
        <br />
        <br />
        <br />
      </Content>
      <Header>自审流程图</Header>
      <img className="content-image" src={processPic} alt="" />
    </MainBlock>
  </Container>
);

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isHainan, isBeijing } from "../utils/region";

const Container = styled.div`
  color: #191919;
  background: #e5ebef;

  @media screen and (max-width: 767px) {
    height: 600px;
    font-size: 14px;
  }

  @media screen and (min-width: 768px) {
    height: 280px;
    font-size: 12px;
  }
`;
const Center = styled.div`
  @media screen and (max-width: 767px) {
    margin: 0px 24px 0px 24px;
    padding-top: 50px;
  }
  @media screen and (min-width: 768px) {
    width: 960px;
    margin: 0 auto;
    padding: 32px 0px 20px 0px;
  }
`;
const LinkBlock = styled.div`
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
  @media screen and (min-width: 768px) {
    margin-right: 16px;
  }
`;
const FooterNavigation = styled.div`
  @media screen and (min-width: 768px) {
    display: -webkit-flex;
    -webkit-flex-direction: row;
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
  }

  &.isHainan {
    a {
      color: #333;
      pointer-events: none;
    }
  }
`;
const Rectangle = styled.div`
  width: 1px;
  height: 16px;
  background-color: #999999;
  margin-right: 16px;
`;
const Advice = styled.div`
  color: #666666;
  text-align: Justify;
  text-justify: inter-ideograph;
`;
const CompanyICP = styled.div`
  margin-top: 18px;
`;

export default (props) => (
  <Container>
    <Center>
      <FooterNavigation className={isHainan ? "isHainan" : ""}>
        <LinkBlock>
          <Link to="/doc_tech">技术安全保障措施</Link>
        </LinkBlock>
        <Rectangle className="ef-kids-show-pc" />
        <LinkBlock>
          <Link to="/doc_svc">用户服务协议</Link>
        </LinkBlock>
        <Rectangle className="ef-kids-show-pc" />
        <LinkBlock>
          <Link to="/doc_content">内容自审制度</Link>
        </LinkBlock>
        <Rectangle className="ef-kids-show-pc" />
        <LinkBlock>
          <Link to="/doc_dispute">服务纠纷</Link>
        </LinkBlock>
        <Rectangle className="ef-kids-show-pc" />
        <LinkBlock>
          <Link to="/doc_pg">未成年人家长监控工程</Link>
        </LinkBlock>
      </FooterNavigation>

      <Advice>
        健康忠告：抵制不良内容，拒绝盗版，注意自我保护。合理安排时间，享受健康生活。适度浏览，建议每次浏览不超过1小时，保护眼睛，防止用眼疲劳。
      </Advice>

      {isBeijing && (
        <CompanyICP>
          广播电视节目制作经营许可证（京）字第16952号 
          <a 
            target="_blank"
            href="https://beian.miit.gov.cn"
          >
          京ICP备20013410号-4{" "}
          </a>
          <a
            target="_blank"
            href={`${process.env.PUBLIC_URL}/attachments/Attachment1.png`}
          >
            出版物经营许可证
          </a>{" "}
          京网文 【2020】1769-263号.{" "}
          <a
            target="_blank"
            href={`${process.env.PUBLIC_URL}/attachments/Attachment2.png`}
          >
            营业执照
          </a>
          <br />
          <br />
          公司名称：北京英孚教育科技有限公司
          <br />
          公司地址：北京市平谷区林荫北街13号信息大厦802室-21157（集群注册）
          <br />
          ICP证：
          <a 
            target="_blank"
            href="https://beian.miit.gov.cn"
          >
          京ICP备20013410号-4{" "}
          </a>
          <br />
          联系电话：010-86482585
          <br />
          邮件: compliance.china@ef.cn <br />
        </CompanyICP>
      )}

      {isHainan && (
        <CompanyICP>
          公司名称：海南英孚科技有限公司
          <br />
          公司地址：海南省老城镇高新技术产业示范区海南生态软件园B20幢三层303室
          <br />
          ICP证：琼ICP备2021004100号-1
          <br />
          邮件: compliance.china@ef.cn <br />
        </CompanyICP>
      )}
    </Center>
  </Container>
);

import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { isHainan, isBeijing } from "../utils/region";

const Container = styled.div`
  text-align: center;
  z-index: 1001;
  position: relative;

  &:after {
    content: " ";

    position: static !important;
    top: 100%;
    width: 100%;
    height: 1px;
    display: block;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), transparent);
  }
`;

const Title = styled.div`
  padding: 10px 0;
  @media screen and (max-width: 767px) {
    font-size: 13px;
  }

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
  font-weight: blod;
`;

export default (props) => (
  <Container>
    <Helmet>
      {isBeijing && <title>北京英孚教育科技有限公司</title>}
      {isHainan && <title>海南英孚科技有限公司</title>}
    </Helmet>

    {isBeijing && <Title>北京英孚教育科技有限公司</Title>}
    {isHainan && <Title>海南英孚科技有限公司</Title>}
  </Container>
);

import React from 'react';

import Header from '../components/Header'
import MainNavigation from '../components/MainNavigation'
import Login from '../components/Login'
import Footer from '../components/Footer'

export default (props) => (
    <div>
        <Header></Header>
        <MainNavigation></MainNavigation>
        <Login></Login>
        <Footer></Footer>
    </div>
)
